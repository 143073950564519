import * as React from 'react'
import { SegmentModel, SessionModel } from '../../models'
import { Button, Descriptions, Table } from 'antd'


interface Props {
	match?: any
	history?: any
}

interface State {
	segment?: SegmentModel
	session?: SessionModel
}

export class SegmentDetailsCmp extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props)

		this.state = {}
	}

	fetchData() {
		fetch(
			process.env.REACT_APP_API_URI +
				'/sessions/' +
				this.props.match.params.sessionId +
				'/segments/' +
				this.props.match.params.segmentId
		)
			.then(response => response.json())
			.then(segment => this.setState({ segment }))

		fetch(process.env.REACT_APP_API_URI + '/sessions/' + this.props.match.params.sessionId)
			.then(response => response.json())
			.then(session => this.setState({ session }))
	}

	componentDidMount() {
		this.fetchData()
	}

	statusHistory() {
		const columns = [
			{
				title: 'Time',
				dataIndex: 'time',
				key: 'time'
			},
			{
				title: 'StatusCode',
				dataIndex: 'statusCode',
				key: 'statusCode'
			},
			{
				title: 'RemoteAddr',
				dataIndex: 'remoteAddr',
				key: 'remoteAddr'
			}
		]
		return <Table dataSource={this.state.segment?.responseHistory} columns={columns} pagination={false} />
	}

	getDuration(segment: SegmentModel | undefined) {
		if (!segment) {
			return ''
		}
		return new Date(segment.endTime).getTime() - new Date(segment.startTime).getTime()
	}

	formatBytes(bytes: number, decimals?: number | undefined) {
		if (bytes === 0) return '0 Bytes'
		const k = 1024,
			dm = decimals || 2,
			sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
			i = Math.floor(Math.log(bytes) / Math.log(k))
		return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
	}

	getFileSize() {
		if (!this.state.segment) {
			return ''
		}

		return this.formatBytes(this.state.segment.fileSize)
	}

	getDownloadUrl(withInit: boolean, withAudio: boolean = false): string {
		if (!this.state.segment || !this.state.session) {
			return ''
		}

		if (withInit) {
			const repId = this.state.segment.repId
			const initFilename = this.state.session.representations.find(rep => {return rep.id === repId})?.initFilename

			return (
				process.env.REACT_APP_MEDIA_URI +
				'/output/' +
				this.props.match.params.sessionId +
				'/' +
				this.state.segment.name +
				'?init=' +
				initFilename +
				'&combined=' +
				withAudio
			)
		} else {
			return (
				process.env.REACT_APP_MEDIA_URI +
				'/output/' +
				this.props.match.params.sessionId +
				'/' +
				this.state.segment.name
			)
		}
	}

	avSyncClick=()=> {
		let path = '/sessions/' + this.state.segment?.sessionId + '/segments/' + this.state.segment?.id + '/avsync'
		this.props.history.push({
			pathname: path
		})
	}

	avSyncButton() {
		if (!this.state.segment || !this.state.session) {
			return ''
		}

		const repId = this.state.segment.repId
		const isVideo = this.state.session.representations.find(rep => {return rep.id === repId})?.mimeType.includes("video")
		if (!isVideo) {
			return ''
		}

		return (
			<Button type="primary" onClick={this.avSyncClick}>
				Analyse A/V sync
			</Button>
		)
	}

	getCombinedLink(){
		const repId = this.state.segment?.repId
		if(!repId){
			return "";
		}
		const isVideo = this.state.session?.representations.find(rep => {return rep.id === repId})?.mimeType.includes("video")
		if(!isVideo){
			return "";
		}
		return (
			<a target="_blank" rel="noopener noreferrer" href={this.getDownloadUrl(true, true)}>
				With audio
			</a>
		)
	}

	render() {
		// const duration = new Date(this.state.segment?.endTime).getTime() - new Date(segment.startTime).getTime()

		return (
			<div>
				<h2>segment details</h2>
				<Descriptions title="General">
					<Descriptions.Item label="Name">{this.state.segment?.name}</Descriptions.Item>
					<Descriptions.Item label="File size">{this.getFileSize()}</Descriptions.Item>
					<Descriptions.Item label="Download">
						<a target="_blank" rel="noopener noreferrer" href={this.getDownloadUrl(false)}>
							Raw
						</a>
						,
						<a target="_blank" rel="noopener noreferrer" href={this.getDownloadUrl(true)}>
							With init
						</a>
						,
						{this.getCombinedLink()}
					</Descriptions.Item>
				</Descriptions>

				<Descriptions title="Download info">
					<Descriptions.Item label="Start time">{this.state.segment?.startTime}</Descriptions.Item>
					<Descriptions.Item label="End time">{this.state.segment?.endTime}</Descriptions.Item>
					<Descriptions.Item label="Duration">{this.getDuration(this.state.segment)}ms</Descriptions.Item>
				</Descriptions>

				<Descriptions title="Mp4 info">
					<Descriptions.Item label="Total frame time">
						{this.state.segment?.mp4Info.duration} seconds
					</Descriptions.Item>
				</Descriptions>

				{this.statusHistory()}

				{this.avSyncButton()}
			</div>
		)
	}
}
