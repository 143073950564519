import React, { useContext } from 'react'
import { AppContext } from '../../libs/ContextLib'
import { withRouter, Redirect } from 'react-router'

interface Props {
	location?: any
	children?: any
}

function AuthLoadingInt(props: Props) {
	const ctx = useContext(AppContext)

	if (ctx?.initialized !== true) {
		return <div>Authenticating</div>
	} else {
		if (ctx.authenticated || props.location.pathname.toUpperCase() === '/LOGIN') {
			return <div>{props.children}</div>
		} else {
			return <Redirect to="/login" />
		}
	}
}

export const AuthLoading = withRouter(AuthLoadingInt)
