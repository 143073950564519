import * as React from 'react'
import { Layout } from 'antd'
import { Status } from '../Containers/Status'

const { Header } = Layout

export enum LogoSize {
	SMALL,
	NORMAL,
	LARGE
}

interface Props {
	size: LogoSize
}

interface State {}

export class Logo extends React.Component<Props, State> {
	render() {
		let logoText = 'Livery stream monitor'

		if (this.props.size === LogoSize.SMALL) logoText = 'T'

		return <Header style={{ background: '#fff', padding: '0px 0px 0px 16px', height: '104px'}}>{logoText} 
				<Status/> 
			   </Header> 
	}
}
