import * as React from 'react'
import { AppContext } from './ContextLib'

export class AuthChecker extends React.Component {
	static contextType = AppContext

	componentDidMount() {
		const context = this.context
		fetch(process.env.REACT_APP_API_URI + '/auth').then(response => {
			if (response.ok) {
				context.setAuthenticated(true)
			} else {
				context.setAuthenticated(false)
			}
			context.setInitialized(true)
		})
	}

	render() {
		return <div></div>
	}
}
