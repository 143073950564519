import React, { useState, ReactNode, useMemo } from 'react'
import { AppContext } from './ContextLib'
import { Api } from './Api'

type Props = {
	children: ReactNode
}

export const ContextProvider = ({ children }: Props) => {
	const [authenticated, setAuthenticated] = useState(false)
	const [initialized, setInitialized] = useState(false)
	const api = useMemo(() => {
		return new Api(setAuthenticated)
	}, [])

	return (
		<AppContext.Provider value={{ authenticated, setAuthenticated, initialized, setInitialized, api }}>
			{children}
		</AppContext.Provider>
	)
}
