import * as React from 'react'
import { SegmentModel } from '../../models'
import styles from './SegmentCmp.module.css'
import { Popover } from 'antd'
import { Link } from 'react-router-dom'
import CSS from 'csstype'

interface Props {
	segment: SegmentModel
	style: CSS.Properties
}

interface State {}

export class SegmentCmp extends React.Component<Props, State> {
	getSegText(): string {
		const text = this.props.segment.name.split('-').pop()
		return text === undefined ? '' : text
	}

	getPopOver(): any {
		const segment = this.props.segment
		const duration = new Date(segment.endTime).getTime() - new Date(segment.startTime).getTime()
		return (
			<div>
				<p>StartTime: {segment.startTime}</p>
				<p>EndTime: {segment.endTime}</p>
				<p>Download duration: {duration}ms</p>
			</div>
		)
	}

	getSegmentClass(statusCode: number) {
		if (statusCode > 300 || statusCode < 100) {
			return styles.failedSegment
		}
		return styles.segment
	}

	render() {
		return (
			<li className={this.getSegmentClass(this.props.segment.statusCode)} style={this.props.style}>
				<Popover content={this.getPopOver()} title={this.props.segment.name}>
					<Link
						className={styles.segmentlink}
						to={'/sessions/' + this.props.segment.sessionId + '/segments/' + this.props.segment.id}
					>
						<div className={styles.segmentInner}>{this.getSegText()}</div>
					</Link>
				</Popover>
			</li>
		)
	}
}
