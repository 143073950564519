import React, { useContext, useState, useEffect, useCallback } from 'react'
import { AppContext } from '../../libs/ContextLib'
import { StatusModel } from '../../models'

interface Props {
}


export const Status = (props: Props) => {
    const { api } = useContext(AppContext)
    const [status, setStatus] = useState<StatusModel | undefined>(undefined)


    const fetchData = useCallback(() => {
    if (api === undefined) {
        return
    }
    api.apiFetch(process.env.REACT_APP_API_URI + '/status')
        .then(response => response.json())
        .then(status => setStatus(status))
    },[api]);

    useEffect(() => {
        fetchData()
        const tick = setInterval(() => {
            fetchData()
        }, 10000)
        return () => clearInterval(tick)
    }, [api, fetchData])

    return (
		<div style={{height: 'initial', lineHeight: 'initial'}}>Diskspace: {status?.freeDiskSpace}</div>
    )
}

