import * as React from 'react'
import { Form, Input, Modal, Select } from 'antd'
import { FormInstance } from 'antd/lib/form'
import { SelectValue, LabeledValue } from 'antd/lib/select'
const { Option } = Select

interface Props {
	onDone?: () => void
	visible: boolean
}

interface State {
	creatingNewSession: boolean
}

export class CreateSessionCmp extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props)

		this.state = {
			creatingNewSession: false
		}
	}

	formRef = React.createRef<FormInstance>()

	handleOk() {
		this.setState({
			creatingNewSession: true
		})

		if (this.formRef.current) {
			const url = this.formRef.current.getFieldValue('url')
			const description = this.formRef.current.getFieldValue('description')

			const requestOptions = {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json'
				},
				body: JSON.stringify({
					url: url,
					description: description
				})
			}

			fetch(process.env.REACT_APP_API_URI + '/sessions', requestOptions).then(() => {
				if (this.props.onDone) {
					this.props.onDone()
				}

				this.setState({
					creatingNewSession: false
				})
			})
		}
	}

	handleCancel() {
		if (this.props.onDone) {
			this.props.onDone()
		}
	}

	handlePresetChange(value: SelectValue) {
		var labeledValue = value as LabeledValue

		if (this.formRef.current) {
			this.formRef.current.setFieldsValue({ url: labeledValue.key })
			this.formRef.current.setFieldsValue({ description: labeledValue.label })
		}
	}

	render() {
		const layout = {
			labelCol: { span: 8 },
			wrapperCol: { span: 16 }
		}

		return (
			<Modal
				title="Create new session"
				visible={this.props.visible}
				onOk={() => this.handleOk()}
				confirmLoading={this.state.creatingNewSession}
				onCancel={() => this.handleCancel()}
			>
				<p>Start a new session</p>

				<Form {...layout} ref={this.formRef} name="control-ref">
					<Form.Item name="preset" label="Preset">
						<Select
							labelInValue
							placeholder="Select a stream"
							onChange={v => this.handlePresetChange(v)}
							allowClear
						>
							<Option value="https://livery-ull-test.akamaized.net/cmaf/live-ull/2021526/5ddb98f5e4b0937e6a4507f2/out.mpd">
								Demo
							</Option>
							<Option value="https://livery-ull-test.akamaized.net/cmaf/live-ull/2023612/5fdb51ade4b08697b135df0e/out.mpd">
								Demo livery live
							</Option>
							<Option value="other">other</Option>
						</Select>
					</Form.Item>
					<Form.Item name="url" label="Url" rules={[{ required: true }]}>
						<Input />
					</Form.Item>
					<Form.Item name="description" label="Description" rules={[{ required: false }]}>
						<Input />
					</Form.Item>
				</Form>
			</Modal>
		)
	}
}
