import * as React from 'react'
import { Menu } from 'antd'
import { SettingOutlined } from '@ant-design/icons'
import { Link } from 'react-router-dom'

interface Props {
	label: string
	link: string
}

interface State {}

export class MainMenuItem extends React.Component<Props, State> {
	render() {
		return (
			<Menu.Item {...this.props}>
				<Link to={this.props.link}>
					<SettingOutlined />
					<span>{this.props.label}</span>
				</Link>
			</Menu.Item>
		)
	}
}
