import * as React from 'react'
import { SessionModel, RepresentationModel, SegmentModel } from '../../models'
import { SegmentCmp } from './SegmentCmp'
import styles from './RepresentationCmp.module.css'
import CSS from 'csstype'

interface Props {
	representation: RepresentationModel
	segments: SegmentModel[]
	session: SessionModel
	zoomLevel: number
}

interface State {}

export class RepresentationCmp extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props)

		this.state = {}
	}

	segStyle(segment: SegmentModel): CSS.Properties {
		const session = this.props.session
		const segStartTime = new Date(segment.startTime).getTime()
		const sessionStartTime = new Date(session.startTime).getTime()
		const segEndTime = new Date(segment.endTime).getTime()
		const relStartTime = segStartTime - sessionStartTime
		const zoomFactor = 5 * this.props.zoomLevel

		let duration = 1000
		if (segEndTime > 1000) {
			duration = segEndTime - segStartTime
		}

		return {
			left: `${relStartTime / zoomFactor}px`,
			width: `${duration / zoomFactor}px`
		}
	}

	mpdRestartStyle(time: Date): CSS.Properties {
		const session = this.props.session
		const sessionStartTime = new Date(session.startTime).getTime()
		const relStartTime = time.getTime() - sessionStartTime
		const zoomFactor = 5 * this.props.zoomLevel

		let duration = 10

		return {
			left: `${relStartTime / zoomFactor}px`,
			width: `${duration}px`,
			minWidth: `${duration}px`
		}
	}

	render() {
		return (
			<div className={styles.track}>
				representation: {this.props.representation.id}, bitrate: {this.props.representation.bandwidth}, nr of
				recorded segments: {this.props.segments.length}
				<ul>
					{this.props.segments.map(segment => (
						<SegmentCmp segment={segment} style={this.segStyle(segment)} key={segment.id} />
					))}

					{this.props.session.mpdReloads.map(mpdReload => (
						<li
							key={new Date(mpdReload).getTime()}
							className={styles.mpdReload}
							style={this.mpdRestartStyle(new Date(mpdReload))}
						></li>
					))}
				</ul>
			</div>
		)
	}
}
