import React, { useContext, useState, useEffect } from 'react'
import { Button, Table } from 'antd'
import { Link } from 'react-router-dom'
import { SessionModel } from '../../models'
import { CreateSessionCmp } from './CreateSessionCmp'
import { AppContext } from '../../libs/ContextLib'

interface Props {}

interface State {
	data?: SessionModel[]
	newSessionVisible: boolean
}

export const Home = () => {
	const { api } = useContext(AppContext)
	const [sessions, setSessions] = useState<SessionModel[]>([])
	const [newSessionVisible, setNewSessionVisible] = useState(false)
	const [apiRefresh, setApiRefresh] = useState(new Date())

	const createSession = () => {
		setNewSessionVisible(true)
	}

	const createDone = () => {
		setNewSessionVisible(false)
	}

	const deleteSession = (session: SessionModel) => {
		const requestOptions: RequestInit = {
			method: 'DELETE'
		}

		api?.apiFetch(process.env.REACT_APP_API_URI + '/sessions/' + session.id, requestOptions).then(() => {
			setApiRefresh(new Date())
		})
	}

	const getDuration = (session: SessionModel): string => {
		const startTime = new Date(session.startTime)
		const endTime = new Date(session.endTime)
		let duration = new Date(endTime.getTime() - startTime.getTime())

		if (endTime.getTime() < 1) {
			return ''
		}

		return duration.toISOString().substr(11, 8)
	}

	useEffect(() => {
		if (api === undefined) {
			return
		}
		api.apiFetch(process.env.REACT_APP_API_URI + '/sessions')
			.then(response => {
				return response.json()
			})
			.then(data => {
				setSessions(data)
			})
	}, [api, newSessionVisible, apiRefresh])

	const columns = [
		{
			title: 'Id',
			dataIndex: 'id',
			key: 'id',
			render: (text: string, record: SessionModel) => <Link to={'/sessions/' + record.id}>{text}</Link>
		},
		{
			title: 'URL',
			dataIndex: 'url',
			key: 'url'
		},
		{
			title: 'Description',
			dataIndex: 'description',
			key: 'description'
		},
		{
			title: 'Start time',
			dataIndex: 'startTime',
			key: 'startTime'
		},
		{
			title: 'Duration',
			key: 'duration',
			render: (text: string, record: SessionModel) => <span>{getDuration(record)}</span>
		},
		{
			title: 'Actions',
			key: 'action',
			render: (text: string, record: SessionModel) => (
				<Link to="" onClick={() => deleteSession(record)}>
					Delete
				</Link>
			)
		}
	]

	return (
		<div>
			<h2>Sessions</h2>

			<Table columns={columns} dataSource={sessions} rowKey="id" />

			<Button type="primary" onClick={() => createSession()}>
				Start recording
			</Button>

			<CreateSessionCmp visible={newSessionVisible} onDone={() => createDone()}></CreateSessionCmp>
		</div>
	)
}
