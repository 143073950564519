import React, { useContext, useState, useEffect, useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { Button, Descriptions, Slider, Modal } from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import { SessionModel, SegmentModel, StreamModel, RepresentationModel } from '../../models'
import { RepresentationCmp } from './RepresentationCmp'
import styles from './Session.module.css'
import CSS from 'csstype'
import { SliderValue } from 'antd/lib/slider'
import { AppContext } from '../../libs/ContextLib'

interface Props {
	match?: any
}

const processingData = {
	processingStatus: 0
}

export const Session = (props: Props) => {
	const { api } = useContext(AppContext)
	const [segments, setSegments] = useState<SegmentModel[]>([])
	const [session, setSession] = useState<SessionModel | undefined>(undefined)
	const [zoomLevel, setZoomLevel] = useState(16)
	const [apiRefresh, setApiRefresh] = useState(new Date())
	const [shouldRefreshSegments, setShouldRefreshSegments] = useState(true)
	const history = useHistory()

	const getSegments = (repId: string): SegmentModel[] => {
		if (!segments) {
			return []
		}
		return segments.filter(item => item.repId === repId)

		// if (!this.state.session?.startTime) {
		//     return []
		// }

		// var list: SegmentModel[] = [];
		// var counter = 0
		// const duration = 1000 * 60 * 60;
		// const filterStartDate = new Date(new Date(this.state.session.startTime).getTime() + 1.2*duration);
		// const filterEndDate = new Date(filterStartDate.getTime() + duration);

		// for (let i = 0; i < this.state.segments.length; i++) {
		//     const seg = this.state.segments[i];

		//     if (seg.repId == repId) {
		//         const segStartTime = new Date(seg.startTime);
		//         if (segStartTime >= filterStartDate && segStartTime <= filterEndDate) {
		//             list.push(seg);
		//             counter++;
		//         }
		//     }

		//     if (counter > 1000) {
		//         break;
		//     }
		// }

		// return list;
	}

	const getRepComp = (item: RepresentationModel) => {
		if (session) {
			return (
				<RepresentationCmp
					key={item.id}
					session={session}
					representation={item}
					segments={getSegments(item.id)}
					zoomLevel={zoomLevel}
				/>
			)
		} else {
			return
		}
	}

	const getSessionTitle = (): string => {
		if (!session) {
			return ''
		}

		const startTime = new Date(session.startTime)
		const formattedDate = new Intl.DateTimeFormat('default', {
			year: 'numeric',
			month: 'numeric',
			day: 'numeric',
			hour: 'numeric',
			minute: 'numeric'
		}).format(startTime)

		return session.description + ' - ' + formattedDate + ' (local time)'
	}

	const stopButton = () => {
		if (!session) {
			return
		}

		if (session.done) {
			return
		}

		return (
			<Button type="primary" onClick={() => stopClick()}>
				Stop recording
			</Button>
		)
	}

	const stopClick = () => {
		const requestOptions = {
			method: 'POST'
		}

		fetch(process.env.REACT_APP_API_URI + '/sessions/' + props.match.params.id + '/stop', requestOptions).then(
			() => {
				setApiRefresh(new Date())
			}
		)
	}

	const createVideoClick = () => {
		const requestOptions = {
			method: 'POST'
		}

		fetch(process.env.REACT_APP_API_URI + '/sessions/' + props.match.params.id + '/video', requestOptions).then((response: Response)=>{
			if(response.status < 200 || response.status >= 300) {
				showVideoFileCreationErrorMessage(response.status, response.statusText);
			}
			fetchData();
		});
	}

	const onZoomChange = (value: SliderValue) => {
		setZoomLevel(Number.parseInt(value.toString()))
	}

	const tracksStyle = (): CSS.Properties => {
		return {}

		// const session = this.state.session
		// if (!session)
		//     return {}

		// //TODO: Attempt to make the tracks the same width as the absolute positioned segments
		// //Not working yet
		// const sessionStartTime = new Date(session.startTime).getTime()
		// const sessionEndTime = new Date(session.endTime).getTime()
		// const duration = sessionEndTime - sessionStartTime

		// return {
		//     width: '3000px'
		// }
	}

	const getFileSize = (bytes: number) => {
		var exp = Math.log(bytes) / Math.log(1024) | 0;
		var result = (bytes / Math.pow(1024, exp)).toFixed(2);
	
		return result + ' ' + (exp === 0 ? 'bytes': 'KMGTPEZY'[exp - 1] + 'B');
	}

	const getRecordedVideoElement = (stream: StreamModel, i: number) => {
		if (!stream.recordedVideos) return
		if (i >= stream.recordedVideos.length) return

		const video = stream.recordedVideos[i]
		const url = process.env.REACT_APP_MEDIA_URI + '/download/' + video.filename
		const fileSize = getFileSize(video.fileSize)


		return (
			<li key={i}>
				<a href={url}>Video file {stream.mpdNr}-{i}</a> ({fileSize})
			</li>
		)
	}
	
	const VideoButton = () => {
		const [loading, setLoading] = useState(false);

		if(session) {
			processingData.processingStatus = session.processingStatus;
		}

		useEffect(() => {
			setLoading(processingData.processingStatus > 0);
		}, []);

		if (!session?.streams) return
		if (session.streams[0].recordedVideos) {
			if (session.streams[0].recordedVideos.length > 0 && processingData.processingStatus === 0) return
		}

		const handleClick = () => {
			session.processingStatus = 1;
			setLoading(session.processingStatus > 0);
			createVideoClick();
		};

		return <Button onClick={() => {handleClick();}} loading={loading}>Create video</Button>
	}

	const getDuration = () => {
		if (!session) return ''

		if (new Date(session.endTime).getTime() < 1) {
			return ''
		}

		const duration = new Date(new Date(session.endTime).getTime() - new Date(session.startTime).getTime())
		return duration.toISOString().substr(11, 8)
	}

	const showDeleteConfirm = () => {
		Modal.confirm({
			title: 'Are you sure you want to delete this recording?',
			icon: <ExclamationCircleOutlined />,
			content: 'This is not reversable, there is no backup.',
			okText: 'Yes',
			okType: 'danger',
			cancelText: 'No',
			onOk() {
				const requestOptions: RequestInit = {
					method: 'DELETE'
				}

				api?.apiFetch(process.env.REACT_APP_API_URI + '/sessions/' + session?.id, requestOptions).then(() => {
					history.push('/')
				})
			}
		})
	}

	const showVideoFileCreationErrorMessage = (status: number, statusText: String) => {
		Modal.error({
			title: 'Something went wrong while trying to create video files.',
			icon: <ExclamationCircleOutlined />,
			content: status + ": " + statusText,
			okText: 'Ok',
			okType: 'danger',
		})
	}

	const fetchData = useCallback(() => {
		if (api === undefined) {
			return
		}

		if (shouldRefreshSegments) {
			api.apiFetch(process.env.REACT_APP_API_URI + '/sessions/' + props.match.params.id + '/segments')
			.then(response => response.json())
			.then(segments => setSegments(segments))
		}

		api.apiFetch(process.env.REACT_APP_API_URI + '/sessions/' + props.match.params.id)
			.then(response => response.json())
			.then(session => {
				setSession(session)

				if (session?.endTime !== undefined && session?.endTime !== "0001-01-01T00:00:00Z") {
					setShouldRefreshSegments(false)
				}
			})

		

	},[api, props.match.params.id, shouldRefreshSegments]);

	useEffect(() => {
		fetchData()
		const tick = setInterval(() => {
			fetchData()
		}, 10000)
		return () => clearInterval(tick)
	}, [api, props.match.params.id, apiRefresh, fetchData])

	return (
		<div className={styles.sessionPage}>
			<h2>{getSessionTitle()}</h2>
			<Descriptions>
				<Descriptions.Item label="Id">{props.match.params.id}</Descriptions.Item>
				<Descriptions.Item label="Description">{session?.description}</Descriptions.Item>
				<Descriptions.Item label="Start time">{session?.startTime}</Descriptions.Item>
				<Descriptions.Item label="End time">{session?.endTime}</Descriptions.Item>
				<Descriptions.Item label="Duration">{getDuration()}</Descriptions.Item>
				<Descriptions.Item label="URL">{session?.url}</Descriptions.Item>
			</Descriptions>

			<ul className={styles.tracks} style={tracksStyle()}>
				{session?.representations.map(item => getRepComp(item))}
			</ul>

			<div className={styles.blockSmall}>
				<Slider
					className={styles.zoomSlider}
					min={1}
					max={100}
					value={zoomLevel}
					onChange={e => onZoomChange(e)}
				/>
				Zoom level
			</div>

			{stopButton()}

			{VideoButton()}

			<Button onClick={showDeleteConfirm} type="dashed">
				Delete
			</Button>

			<div className={styles.block}>
				<ul>
					{session?.streams?.map(stream => (
						<li key={'mpd' + stream.mpdNr} className={styles.mpdItem}>
							<div>
								Live MPD {stream.mpdNr} url:{' '}
								{process.env.REACT_APP_MEDIA_URI +
									'/api/sessions/' +
									session?.id +
									'/playback/' +
									stream.mpdNr}
							</div>
							<div>
								Live MPD {stream.mpdNr}:
								<a
									target="_blank"
									rel="noopener noreferrer"
									href={
										'https://reference.dashif.org/dash.js/v3.1.1/samples/dash-if-reference-player/index.html?url=' +
										process.env.REACT_APP_MEDIA_URI +
										'/api/sessions/' +
										session?.id +
										'/playback/' +
										stream.mpdNr +
										'/live.mpd'
									}
								>
									Dash.js
								</a>
							</div>
							<div>
								On demand MPD {stream.mpdNr}:
								<a
									target="_blank"
									rel="noopener noreferrer"
									href={
										'https://reference.dashif.org/dash.js/v3.1.1/samples/dash-if-reference-player/index.html?url=' +
										process.env.REACT_APP_MEDIA_URI +
										'/api/sessions/' +
										session?.id +
										'/playback/' +
										stream.mpdNr +
										'/ondemand.mpd'
									}
								>
									Dash.js
								</a>
							</div>
							<div>
								<a
									href={
										'https://stream-monitor.liveryvideo.com/output/' +
										session?.id +
										'/' +
										stream.mpdNr +
										'out.mpd'
									}
									target="_blank"
									rel="noopener noreferrer"
								>
									Original mpd
								</a>
							</div>
							<ul>{session?.representations.map((item, i) => getRecordedVideoElement(stream, i))}</ul>
						</li>
					))}
				</ul>
			</div>
		</div>
	)
}
