import * as React from 'react'
import { Logo } from 'components'
import { SiderContextProps, SiderContext } from 'antd/lib/layout/Sider'
import { LogoSize } from 'components/Branding'

interface Props {}

interface State {}

export class MainMenuHeader extends React.Component<Props, State> {
	renderLogo = (siderContextProps: SiderContextProps) => {
		const { siderCollapsed } = siderContextProps

		return <Logo size={siderCollapsed ? LogoSize.SMALL : LogoSize.LARGE} />
	}

	render() {
		return <SiderContext.Consumer>{this.renderLogo}</SiderContext.Consumer>
	}
}
