import * as React from 'react'
import { AppContext } from '../../libs/ContextLib'

interface Props {}

interface State {
	success: boolean
}

export class LogoutCmp extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props)

		this.state = {
			success: false
		}
	}

	static contextType = AppContext

	componentDidMount() {
		const ctx = this.context

		const requestOptions = {
			method: 'DELETE',
			headers: {
				'Content-Type': 'application/json'
			}
		}

		fetch(process.env.REACT_APP_API_URI + '/auth', requestOptions).then(() => {
			ctx.setAuthenticated(false)
			this.setState({
				success: true
			})

			console.log('logout done')
		})
	}

	render() {
		if (this.state.success) {
			return <div>Successfully logged out.</div>
		} else {
			return <div>Logging out.</div>
		}
	}
}
