import React, { Component } from 'react'
import { BrowserRouter } from 'react-router-dom'
import { ContextProvider } from './libs/ContextProvider'
import { AuthChecker } from './libs/AuthChecker'
import { AuthLoading, Screen } from 'components'

class App extends Component {
	render() {
		return (
			<ContextProvider>
				<BrowserRouter>
					<AuthChecker />
					<div className="App">
						<AuthLoading>
							<Screen />
						</AuthLoading>
					</div>
				</BrowserRouter>
			</ContextProvider>
		)
	}
}

export default App
