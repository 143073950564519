import { useContext, createContext } from 'react'
import { Api } from './Api'

export type AppContextType = {
	authenticated: boolean
	setAuthenticated: (value: boolean) => void
	initialized: boolean
	setInitialized: (value: boolean) => void
	api: Api
}

export const AppContext = createContext<Partial<AppContextType>>({})

export function useAppContext() {
	return useContext(AppContext)
}
