import * as React from 'react'
import { AVSyncOutputModel } from '../../models'
import { Divider } from 'antd'

interface Props {
	match?: any
}

interface State {
	output?: AVSyncOutputModel
}

export class AVSyncCmp extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props)

		this.state = {}
	}

	fetchData() {
		fetch(
			process.env.REACT_APP_API_URI +
				'/sessions/' +
				this.props.match.params.sessionId +
				'/segments/' +
				this.props.match.params.segmentId +
				'/avsync'
		)
			.then(response => response.json())
			.then(output => this.setState({ output: output }))
	}

	componentDidMount() {
		this.fetchData()
	}

	outputLines(): string {
		if (!this.state.output) {
			return ''
		}

		let rows = ''
		this.state.output.lines.forEach(line => {rows += line + '\n\r'})

		return rows
	}

	render() {

		return (
			<div>
				<h2>A/V sync test</h2>
				The audio and video segment are combined and analysed. This will only work if one of <a href="https://photojoseph.com/AVsyncTest" target="blank">these videos</a> is streamed full screen.
				<Divider>Output</Divider>
				<pre>{this.outputLines()}</pre>

			</div>
		)
	}
}
