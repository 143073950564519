export class Api {
	private authenticated = false
	private callback: (authenticated: boolean) => void
	constructor(callback: (authenticated: boolean) => void) {
		console.log('INIT')
		this.callback = callback
	}
	private setAuth(authenticated: boolean) {
		this.authenticated = authenticated
		this.callback(this.authenticated)
	}

	request() {
		console.log('some api call that returns 401')
		this.setAuth(!this.authenticated)
	}

	/**
	 * Do a fetch request and set unautorized on 401 response
	 */
	async apiFetch(input: RequestInfo, init?: RequestInit): Promise<Response> {
		const value = await fetch(input, init)
		if (value.status === 401) {
			this.setAuth(false)
		}
		return value
	}
}
