import * as React from 'react'
import { Form, Input, Button, Alert } from 'antd'
import { AppContext } from '../../libs/ContextLib'
import { Redirect } from 'react-router'

const layout = {
	labelCol: { span: 8 },
	wrapperCol: { span: 16 }
}
const tailLayout = {
	wrapperCol: { offset: 8, span: 16 }
}

interface Props {}

interface State {
	errorMsg: string
	loginOk: boolean
}

export class LoginCmp extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props)

		this.state = {
			errorMsg: '',
			loginOk: false
		}
	}

	static contextType = AppContext

	redirect = () => {
		if (this.state.loginOk) {
			return <Redirect to="/" />
		}
	}

	onFinish = (values: any) => {
		const context = this.context
		const requestOptions = {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({
				username: values.username,
				password: values.password
			})
		}

		fetch(process.env.REACT_APP_API_URI + '/auth', requestOptions).then(
			result => {
				if (result.ok) {
					context.setAuthenticated(true)
					this.setState({
						errorMsg: '',
						loginOk: true
					})
					console.log('login done')
				} else {
					console.log(result)
					this.setState({
						errorMsg: 'Unable to log in, please try again.'
					})
				}
			},
			error => {
				this.setState({
					errorMsg: 'Unable to log in, please try again.'
				})
				console.log(error)
			}
		)
	}

	alerter = () => {
		if (this.state.errorMsg !== '') {
			return (
				<Form.Item {...tailLayout}>
					<Alert message={this.state.errorMsg} type="error" />
				</Form.Item>
			)
		}
	}

	render() {
		return (
			<Form {...layout} name="basic" initialValues={{ remember: true }} onFinish={this.onFinish}>
				<Form.Item
					label="Username"
					name="username"
					rules={[{ required: true, message: 'Please input your username!' }]}
				>
					<Input />
				</Form.Item>

				<Form.Item
					label="Password"
					name="password"
					rules={[{ required: true, message: 'Please input your password!' }]}
				>
					<Input.Password />
				</Form.Item>

				{this.alerter()}

				<Form.Item {...tailLayout}>
					<Button type="primary" htmlType="submit">
						Submit
					</Button>
				</Form.Item>

				{this.redirect()}
			</Form>
		)
	}
}
