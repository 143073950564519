import * as React from 'react'
import { Layout } from 'antd'
import { MainMenuItems } from 'components'
import { MainMenuHeader } from './MainMenuHeader'

const { Sider } = Layout

export class MainMenu extends React.Component {
	state = {
		collapsed: false
	}

	onCollapse = (collapsed: any) => {
		this.setState({ collapsed })
	}

	render() {
		return (
			<Sider collapsible collapsed={this.state.collapsed} onCollapse={this.onCollapse} {...this.props}>
				<MainMenuHeader />
				<MainMenuItems />
			</Sider>
		)
	}
}
