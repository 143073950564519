import * as React from 'react'
import { Layout } from 'antd'
import { MainMenu } from 'components'
import { Switch, Route } from 'react-router-dom'
import { Home } from './Home'
import { Session } from './Session'
import { AVSyncCmp } from './AVSyncCmp'
import { SegmentDetailsCmp } from './SegmentDetailsCmp'
import { LoginCmp } from './LoginCmp'
import { LogoutCmp } from './LogoutCmp'

const { Content } = Layout

export class Screen extends React.Component {
	render() {
		return (
			<Layout style={{ minHeight: '100vh' }}>
				<MainMenu />
				<Layout style={{ padding: 24, minHeight: 280 }}>
					<Content style={{ background: '#fff', padding: 24 }}>
						<Switch>
							<Route path="/sessions/:sessionId/segments/:segmentId/avsync" component={AVSyncCmp} />
							<Route path="/sessions/:sessionId/segments/:segmentId" component={SegmentDetailsCmp} />
							<Route path="/sessions/:id" component={Session} />
							<Route path="/login" component={LoginCmp} />
							<Route path="/logout" component={LogoutCmp} />
							<Route path="/">
								<Home />
							</Route>
						</Switch>
					</Content>
				</Layout>
			</Layout>
		)
	}
}
