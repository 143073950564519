import React, { useContext } from 'react'
import { Menu } from 'antd'
import { MainMenuItem } from './MainMenuItem'
import { useLocation } from 'react-router-dom'
import { AppContext } from '../../libs/ContextLib'

//Made this a functional component to be able to use the useLocation hook
export const MainMenuItems = () => {
	const authenticated = useContext(AppContext)?.authenticated!
	const path = useLocation().pathname
	if (authenticated) {
		return (
			<div>
				<Menu theme="dark" selectedKeys={[path]} mode="inline">
					<MainMenuItem key="/" label="Home" link="" />
					<MainMenuItem key="/logout" label="Log-out" link="/logout" />
				</Menu>
			</div>
		)
	} else {
		return (
			<div>
				<Menu theme="dark" selectedKeys={[path]} mode="inline">
					<MainMenuItem key="/login" label="Log-in" link="/login" />
				</Menu>
			</div>
		)
	}
}
